import { DropdownItem } from "../atom/dropdown";

import { useNavigate } from "react-router-dom";

import { useDuplicateInventoryMutation } from "@app/graphql/pim";

export default function DuplicateInventory({
  inventoryId,
}: {
  inventoryId: string;
}) {
  const navigate = useNavigate();
  const [duplicate] = useDuplicateInventoryMutation();

  const duplicateInventory = async () => {
    const newInventory = await duplicate({
      variables: { inventoryId: inventoryId },
    });
    if (!newInventory?.data?.duplicateInventory?.inventory?.id) {
      alert("Kunne ikke lage en kopi av varen");
      return;
    }
    navigate(
      `/partner/${newInventory?.data?.duplicateInventory?.inventory?.id}`
    );
  };
  return <DropdownItem onClick={duplicateInventory}>Lag en kopi</DropdownItem>;
}
