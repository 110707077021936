import {
  InventoryType,
  ObjectType,
  useCreateInventoryMutation,
  usePartnerInventoryLazyQuery,
  useCreatePartnerInventroyMutation,
} from "@app/graphql/pim";
import {
  BooleanInput,
  ImageInput,
  OptionsInput,
  ReferenceInput,
  Text,
} from "./CMSInputs";
import { BicycleSearch } from "../molecules/BicycleSearch";
import Loading from "../molecules/Loading";
import LoadingInline from "../molecules/LoadingInline";
import { Button } from "../atom/button";
import { Fieldset, FieldGroup, Field, Legend, Label } from "../atom/fieldset";
import { Input } from "../atom/input";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
type TypeMap = {
  [key in ObjectType]: {
    title: string;
    inventoryTypes: {
      type: InventoryType;
      title: string;
    }[];
  };
};
export default function CreatePartnerInventory() {
  const [name, setName] = useState<string>("");
  const [bicycleId, setBicycleId] = useState<number | undefined>();
  const [objectType, setObjectType] = useState<ObjectType | undefined>();
  const [inventoryType, setInventoryType] = useState<
    InventoryType | undefined | null
  >();
  const [year, setYear] = useState<number | undefined>();

  //@ts-ignore
  const types: TypeMap = {
    BICYCLE: {
      title: "Sykkel",
      inventoryTypes: [
        {
          type: InventoryType.Outlet,
          title: "Outlet",
        },
        {
          type: InventoryType.Certified,
          title: "Sertifisert Brukt",
        },
        { type: InventoryType.Demo, title: "Demo" },
      ],
    },

    SKI: {
      title: "Ski",
      inventoryTypes: [
        {
          type: InventoryType.Outlet,
          title: "Outlet",
        },
      ],
    },
  };

  const [createInventory] = useCreatePartnerInventroyMutation();
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!objectType || !inventoryType || !name || !year) {
      return;
    }
    const inventory = await createInventory({
      variables: {
        name,
        objectType,
        inventoryType,
        bicycleId: bicycleId || 0,
        year,
      },
    });
    if (inventory.data?.createPartnerInventory?.inventory) {
      navigate(`${inventory.data.createPartnerInventory.inventory.id}`);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Fieldset>
        <FieldGroup>
          <OptionsInput
            name="objectType"
            nullable={false}
            options={Object.keys(types).map((key) => ({
              id: key,
              name: types[key as ObjectType].title,
            }))}
            value={objectType}
            onChange={(field: string, value: string) => {
              setInventoryType(undefined);
              setName("");

              setObjectType(value as ObjectType);
            }}
          >
            Objekttype
          </OptionsInput>
          {objectType && (
            <OptionsInput
              name="inventoryType"
              key={objectType}
              nullable={false}
              options={types[objectType]?.inventoryTypes.map((type) => ({
                id: type.type,
                name: type.title,
              }))}
              value={inventoryType}
              onChange={(field: string, value: string) =>
                setInventoryType(value as InventoryType)
              }
            >
              Annonsetype
            </OptionsInput>
          )}

          <Field>
            {objectType === ObjectType.Bicycle && inventoryType && (
              <BicycleSearch
                bicycleName={name}
                setBicycleName={(bicycleName) => setName(bicycleName || "")}
                bicycleId={bicycleId}
                setYear={(year) => setYear(year || undefined)}
                setBicycleId={(bicycleId) =>
                  setBicycleId(bicycleId || undefined)
                }
              />
            )}
          </Field>
          {objectType === ObjectType.Ski && (
            <>
              <Field>
                <Label htmlFor="name">Tittel</Label>
                <Input
                  id="name"
                  name="name"
                  type="string"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Field>
              <Field>
                <Label htmlFor="year">Modellår</Label>
                <Input
                  id="year"
                  name="year"
                  type="number"
                  value={year}
                  onChange={(e) => setYear(Number(e.target.value))}
                />
              </Field>
            </>
          )}
        </FieldGroup>
      </Fieldset>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Button type="submit">Lag ny</Button>
      </div>
    </form>
  );
}

enum Status {
  Checking = "checking",
  Waiting = "waiting",
  Generating = "generating",
  Done = "done",
  Error = "error",
}

export function WaithWhileWorkerIsGeneratingDescription() {
  const params = useParams();
  const inventoryId = params.id;
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.Checking);
  const navigate = useNavigate();
  const [getInventory, { data, loading, error }] = usePartnerInventoryLazyQuery(
    {
      fetchPolicy: "network-only",
    }
  );

  // poll inventory every 5 seconds
  // check for generatedDescription = true, generatedDescriptionTs is not null and generatedDescriptionLog if generatedDescription is false and generatedDescriptionTs is not null

  useEffect(() => {
    if (!inventoryId) return;

    getInventory({
      variables: {
        id: inventoryId,
      },
    });

    const interval = setInterval(() => {
      getInventory({
        variables: {
          id: inventoryId,
        },
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [inventoryId, getInventory]);

  useEffect(() => {
    if (!data?.inventory) return;

    const inventory = data.inventory;

    if (inventory.descriptionGenerated && inventory.descriptionGeneratedTs) {
      setStatus(Status.Done);
      navigate(`/partner/${inventoryId}`);
    } else if (
      !inventory.descriptionGenerated &&
      inventory.descriptionGeneratedTs
    ) {
      if (inventory.descriptionGeneratedLog) {
        setStatus(Status.Error);
        setMessage(inventory.descriptionGeneratedLog);
      } else {
        setStatus(Status.Done);
        navigate(`/partner/${inventoryId}`);
      }
    } else if (inventory.descriptionGenerated) {
      setStatus(Status.Generating);
    } else {
      setStatus(Status.Waiting);
    }
  }, [data]);

  console.log(status, message, loading, error);
  return (
    <div className="flex items-center justify-center h-64">
      {status === Status.Checking ? (
        <div className="flex flex-col items-center space-y-4">
          <LoadingInline />
          <p className="text-gray-600">Checking status...</p>
        </div>
      ) : status === Status.Waiting ? (
        <div className="flex flex-col items-center space-y-4">
          <LoadingInline />
          <p className="text-gray-600">Genererer annonse...</p>
        </div>
      ) : status === Status.Generating ? (
        <div className="flex flex-col items-center space-y-4">
          <LoadingInline />
          <p className="text-gray-600">Genererer annonse...</p>
        </div>
      ) : status === Status.Error ? (
        <div className="flex flex-col items-center space-y-4">
          <p className="text-red-600">An error occurred</p>
          <p className="text-gray-600">{message}</p>
        </div>
      ) : null}
    </div>
  );
}
