import {
  Category,
  ListDescription,
  Locale,
  Navigation,
  Option,
  SchemaEntry,
  Service,
  SubCategory,
} from "@app/types";

// @ts-ignore
const packageJson = require("../../../package.json");

// TODO: customise this with your own settings!

export const fromEmail =
  '"PostGraphile Starter" <no-reply@examples.graphile.org>';
export const awsRegion = "us-east-1";
export const projectName = packageJson.projectName.replace(/[-_]/g, " ");
export const companyName = projectName; // For copyright ownership
export const emailLegalText =
  // Envvar here so we can override on the demo website
  process.env.LEGAL_TEXT || "<Insert legal email footer text here >";

export const domain = process.env.AUTH0_BASE_URL || "https://sportienda.com";

export function conditionsFromData(data: any): SchemaEntry[] {
  return [
    standardConditionSelectors(data),
    damperConditionSelectors(data),
    data.electric ? electriConditionSelectors : [],
    data.bicycleType === "RACE" ? raceBreakType : [],
  ].flat() as SchemaEntry[];
}

export function dbConditionsFromData(data: any): SchemaEntry[] {
  const graphqlConditions = conditionsFromData(data);
  // key from cammelCase to snake_case
  return graphqlConditions.map((condition) => {
    const [key, ...rest] = condition;
    return [key.replace(/([A-Z])/g, "_$1").toLowerCase(), ...rest];
  });
}
export const showDampers = ["HARDTAIL", "FULL_SUSPENSION"];

export const typesFromData = (data: any) => typeSelectors;

export const TextArea = "textarea";

export const emailLists = {
  5: "Nyhetsbrev",
};

export const priceQuerySteps = [
  { id: "01", name: "Sykkeltype", rows: typesFromData },
  { id: "02", name: "Tilstand", rows: conditionsFromData },
  { id: "03", name: "Tilbud" },
  { id: "04", name: "Detaljer og Personalia" },
  { id: "05", name: "Oppsummering" },
];

export const intakeSteps = [
  { id: "01", name: "Tilstand" },
  { id: "02", name: "Oppsummering og innsending" },
];

export const bicycleTypes = [
  {
    id: "HARDTAIL",
    name: "Hardtail",
  },
  {
    id: "FULL_SUSPENSION",
    name: "Fulldemper",
  },
  {
    id: "RACE",
    name: "Landevei",
  },
  {
    id: "GRAVEL",
    name: "Grusracer",
  },
  {
    id: "CITY",
    name: "Bysykkel",
  },
  {
    id: "CARGO",
    name: "Lastesykkel",
  },
  {
    id: "YOUTH",
    name: "Barnesykkel",
  },
  {
    id: "OTHER",
    name: "Tempo / Triatlon",
  },
];

export const bicycleDriveTypes = [
  {
    id: "CHAIN",
    name: "Kjede",
  },
  {
    id: "BELT",
    name: "Belte",
  },
];

export const typeSelectors: SchemaEntry[] = [
  ["bicycleType", "Bekreft sykkeltype", bicycleTypes],
  ["electric", "Bekreft el-sykkel?", Boolean, true],
  ["driveType", "Bekreft drivverk", bicycleDriveTypes],
];

const extraIntakeFields = [
  ["frameNumber", "Rammenummer", String],
  ["batteryIncluded", "Batteri Inkludert", Boolean, true],
  ["keysIncluded", "Nøkler Inkludert", Boolean, true],
  ["keyBrand", "Nøkkelmerke", String, false],
  ["keyNumber", "Nøkkelnumber", String, false],
  ["chargerIncluded", "Lader Inkludert", Boolean, true],
  ["batteryStatusV", "Batteristatus [V]", Number, true],
  ["batteryStatusAh", "Batteristatus [Ah]", Number, true],
  ["batteryStatusWh", "Batteristatus [Wh]", Number, true],
  ["comment", "Kommentar", TextArea, true],
  ["estimatedServiceCost", "Beregnet servicekostnad", Number, true],
];

export const extraIntakeFieldsFromData = (data: any) => extraIntakeFields;

const standardConditionSelectors = (data: any) => {
  return [
    [
      "conditionCosmeticFrame",
      "Bruksmerker og ramme",
      [
        {
          name: "ødelagt",
          description: "store skader i byggematerialet",
          id: -126,
        },
        {
          name: "god stand",
          description: "moderate riper og skrammer",
          id: 10,
        },
        {
          name: "meget god stand",
          description: "Utmerket stand, knapt synlige riper",
          id: 15,
        },
        { name: "som ny", description: "Ikke vært i bruk", id: 20 },
      ],
    ],
    [
      "conditionBottomBracket",
      data?.driveType === "BELT" ? "Nav" : "Drev",
      [
        { name: "Må byttes", description: "ødelagt/utslitt", id: 0 },
        { name: "god stand", description: "fungerer ut sesongen", id: 5 },
        {
          name: "meget god stand",
          description: "minst en sesong igjen",
          id: 12,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 14 },
      ],
    ],
    [
      "conditionCasette",
      data?.driveType === "BELT" ? "Tannhjul" : "Kassett",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        {
          name: "god stand/bør ha service",
          description: "fungerer ut sesongen",
          id: 5,
        },
        {
          name: "meget god stand",
          description: "minst en sesong igjen",
          id: 12,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 14 },
      ],
    ],
    [
      "conditionChain",
      data?.driveType === "BELT" ? "Belte" : "Kjede",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        { name: "god stand", description: "fungerer ut sesongen", id: 4 },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 8 },
        { name: "som nytt", description: "aldri vært i bruk", id: 10 },
      ],
    ],
    [
      "conditionTiresFront",
      "Dekk foran",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        {
          name: "god stand/bør ha service",
          description: "noe slitasje, men fungerer ut sesongen",
          id: 6,
        },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 10 },
        { name: "som nytt", description: "aldri vært i bruk", id: 12 },
      ],
    ],
    [
      "conditionTiresBack",
      "Dekk bak",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        {
          name: "god stand",
          description: "noe slitasje, men fungerer ut sesongen",
          id: 6,
        },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 10 },
        { name: "som nytt", description: "aldri vært i bruk", id: 12 },
      ],
    ],
    [
      "conditionWheelsFront",
      "Felg/hjulsett Foran",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: -50 },
        {
          name: "god stand/bør ha service",
          description: "noe slitasje, men fungerer ut sesongen",
          id: 6,
        },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 13 },
        { name: "som nytt", description: "aldri vært i bruk", id: 18 },
      ],
    ],
    [
      "conditionWheelsBack",
      "Felg/hjulsett Bak",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: -50 },
        {
          name: "bør ha service",
          description: "kast i hjul/nav må smøres",
          id: 6,
        },
        {
          name: "meget god stand",
          description: "brukt, men fungerer optimalt",
          id: 13,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 18 },
      ],
    ],
    [
      "conditionBrakes",
      "Bremser",
      [
        {
          name: "må byttes",
          description: "ødelagt/utslitte klosser eller skiver",
          id: 0,
        },
        {
          name: "bør ha service",
          description: "Bremsene fungerer, men bør luftes/justeres",
          id: 5,
        },
        {
          name: "meget god stand",
          description: "God bremsekraft/ingen ulyder",
          id: 7,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 8 },
      ],
    ],
  ];
};

const damperConditionSelectors = (data: any) => {
  return [
    [
      "conditionDampersFront",
      "Fordemper (Må velges om sykkelen har fordemper)",
      [
        {
          id: -5000,
          name: "Ødelagt",
          description: "fungerer ikke som forventet",
        },
        {
          id: -2500,
          name: "Trenger service",
          description: "Over 12 mnd siden demperservice",
        },
        {
          id: -500,
          name: "Meget god stand",
          description:
            "har hatt demperservice siste 12 mnd - dokumentasjon kreves",
        },
        { id: 0, name: "Som ny", description: "aldri vært i bruk" },
        ...(data.bicycleType !== "FULL_SUSPENSION"
          ? [
              {
                id: null,
                name: "Sykkelen har ikke dempere",
              },
            ]
          : []),
      ],
      data.bicycleType !== "FULL_SUSPENSION",
    ],
    ...(data.bicycleType === "FULL_SUSPENSION"
      ? [
          [
            "conditionDampersBack",
            "Bakdemper.",
            [
              {
                id: -5000,
                name: "Ødelagt",
                description: "fungerer ikke som forventet",
              },
              {
                id: -1500,
                name: "God stand/Trenger service",
                description: "Over 12 mnd siden demperservice",
              },
              {
                id: -500,
                name: "Meget god stand",
                description:
                  "har hatt demperservice siste 12 mnd - dokumentasjon kreves",
              },
              { id: 0, name: "Som ny", description: "aldri vært i bruk" },
            ],
          ],
        ]
      : []),
  ];
};

const electriConditionSelectors = [
  [
    "brandMotor",
    "Merke, elektrisk motor",
    [
      { id: "Yamaha", name: "Yamaha" },
      { id: "Shimano", name: "Shimano" },
      { id: "Bosch", name: "Bosch" },
      { id: "other", name: "Andre" },
    ],
  ],
  ["km", "El-sykkelens kjørelengde i km (se sykkelcomputer)", Number],

  //, "Shimano","Bosch",	"Andre"
];

const raceBreakType = [
  [
    "breakType",
    "Bremsetype",
    [
      { id: "DISC", name: "Bremseskiver" },
      { id: "RIM", name: "Felgbrems" },
    ],
  ],
];

export const ListDescriptions: ListDescription[] = [
  { id: 5, name: "Nyhetsbrev", description: "" },
];

export const InventoryConditions: Option[] = [
  { name: "God stand", id: 2 },
  { name: "Meget god stand", id: 3 },
  { name: "Nytt", id: 4 },
];

export const locales: Locale[] = [
  {
    name: "Norge",
    flag: "no",
    locale: "no",
  },
  // {
  //   name: "Sverige",
  //   flag: "se",
  //   locale: "se",
  // },
];

const skiSubcategory: SubCategory = {
  id: "ski",
  name: "Ski",
  type: "SKI",
  filter: {
    objectType: {
      in: ["SKI"],
    },
  },
};

const bikeSubcategory: SubCategory = {
  id: "bike",
  name: "Bike",
  type: "BICYCLE",
  filter: {
    objectType: {
      in: ["BICYCLE"],
    },
  },
};

export const structure: Category[] = [
  {
    id: "bike",
    name: "Sykkel",
    showSubCategories: true,
    description: `Alle Sportienda sine sykler er pent brukte sykler som har gjennomgått en inntakskontroll, vask, service og sertifisering før salg.

Sportienda behandler hver sykkel gjennom 4 faser og 126 inspeksjonspunkter. Sykkelen din vil ankomme ren, nesten fullt montert og klar til å brukes.

Trenger du hjelp til å finne rett sykkel. [Bruk vår sykkelguide så hjelper vi deg](https://startx.sportienda.com/)
    `,
    filter: {
      objectType: {
        in: ["BICYCLE"],
      },
    },
    type: "bicycle",
    subCategories: [
      {
        id: "electric",
        name: "Elektrisk",
        description:
          "Utforsk vårt imponerende utvalg av elsykler for en moderne og miljøvennlig reiseopplevelse. Enten du pendler til jobb eller utforsker bygatene, tilbyr våre brukte elsykler en pålitelig, økonomisk og bærekraftig måte å bevege seg på. Finn din ideelle elsykkel hos oss og nyt enkelheten og effektiviteten av elektrisk assistanse",
        type: "electric",
        filter: {
          electric: { equalTo: true },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/8ebbcf5e-5e46-4b7b-e078-3ededd182300/listing",
      },
      {
        id: "road",
        name: "Landevei",
        description: `For sykkelentusiaster tilbyr vi et bredt spekter av høykvalitets landeveissykler. Disse syklene er spesialdesignet for fart og avstand, og er perfekte for de som elsker å sykle raskt på åpne veier. Våre brukte landeveissykler gir en optimal balanse mellom ytelse og komfort, og er ideelle for både nybegynnere og erfarne ryttere.`,
        filter: {
          bicycleType: {
            in: ["RACE"],
          },
        },
        type: "race",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/7ab5db92-486d-44e4-daee-41967fbaa000/listing",
      },
      {
        id: "gravel",
        name: "Grus",
        description:
          "Våre grussykler er spesiallaget for eventyr utenfor de bankede baner. Disse allsidige syklene er perfekte for både grusveier og lette stier, og tilbyr en robust og komfortabel tur. Utforsk vårt utvalg av brukte grussykler og forbered deg på ditt neste terrengsykkeleventyr med tillit.",
        type: "gravel",
        filter: {
          bicycleType: {
            in: ["GRAVEL"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/56f053c4-5615-4c8b-228e-c256066de600/listing",
      },
      {
        id: "city",
        name: "By",
        description:
          "Oppdag den perfekte bysykkelen for din urbane livsstil. Våre nøye utvalgte, brukte bysykler kombinerer stil, komfort og funksjonalitet, ideelle for bylivet. Naviger gjennom bygatene med letthet og stil. Våre bysykler er designet for å gi en smidig og behagelig tur, uansett hvor din reise tar deg.",
        filter: {
          bicycleType: {
            in: ["CITY"],
          },
        },
        type: "city",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
      {
        id: "hardtail",
        name: "Hardtail",
        description:
          "For terrengsykkelentusiaster, tilbyr vi et utvalg av hardtail sykler. Med en solid ramme og fjæring kun i front, er disse syklene ideelle for stier og variert terreng. Våre brukte hardtail sykler gir en flott kombinasjon av kontroll, komfort og hastighet, og er perfekte for de som ønsker en robust, men allsidig sykkel.",
        filter: {
          bicycleType: {
            in: ["HARDTAIL"],
          },
        },
        type: "hardtail",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/528820f4-7b06-4390-61f3-94d3924bb400/listing",
      },
      {
        id: "fullsuspension",
        name: "Fulldemper",
        description:
          "Oppdag vårt utvalg av fulldemper terrengsykler, designet for de mest krevende stiene og nedoverbakker. Disse syklene, med både for- og bakdemper, gir overlegen komfort og kontroll på ujevnt terreng. Våre brukte fulldemper sykler er ideelle for eventyrsøkere som ønsker en sykkel som kan takle alt fra tekniske stier til høyhastighets nedoverbakker.",
        filter: {
          bicycleType: {
            in: ["FULL_SUSPENSION"],
          },
        },
        type: "full_suspension",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/239a7836-c258-480a-bf39-e3c566b6e300/listing",
      },
      {
        id: "youth",
        name: "Barn/Junior",
        description:
          "For unge syklister tilbyr vi et nøye utvalgt utvalg av høykvalitets barnesykler. Disse syklene er spesialdesignet for å gi barna en trygg og gøyal sykkelopplevelse, uansett om de er nybegynnere eller allerede er erfarne på to hjul.",
        filter: {
          bicycleType: {
            in: ["YOUTH"],
          },
        },
        type: "youth",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
      {
        id: "cargo",
        name: "Lastesykkel",
        description:
          "For de som trenger ekstra kapasitet på sykkelturen. Disse syklene er designet for både praktisk og komfortabel transport, enten du skal frakte barn, varer eller annet utstyr. Våre lastesykler kombinerer funksjonalitet med kjøreglede, og er ideelle for deg som ønsker en mer bærekraftig transportløsning i hverdagen.",
        filter: {
          bicycleType: {
            in: ["CARGO"],
          },
        },
        type: "cargo",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
      {
        id: "other",
        name: "Tempo/Triathlon",
        description:
          "Disse syklene er spesialdesignet for aerodynamikk og ytelse, noe som gjør dem perfekte for konkurranse og intensiv trening. Våre brukte tempo- og triatlonsykler gir deg muligheten til å presse grensene og nå nye hastigheter, enten du er en erfaren triatlet eller en ivrig mosjonist.",
        filter: {
          bicycleType: {
            in: ["OTHER"],
          },
        },
        type: "other",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
    ],
  },
  {
    id: "ski",
    name: "Ski",
    description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Bruk vår kjøpeguide om du trenger hjelp med å finne skiene som passer dine behov.

[Kjøpeguide](https://sportienda.com/no/x)
`,
    type: "ski",
    showSubCategories: true,
    filter: {
      objectType: {
        in: ["SKI"],
      },
    },
    subCategories: [
      {
        id: "classic",
        name: "Langrenn Klassisk",
        description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Bruk vår kjøpeguide om du trenger hjelp med å finne skiene som passer dine behov.

[Kjøpeguide](https://sportienda.com/no/x)`,
        filter: {
          skiType: {
            in: ["NORDIC_CLASIC"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/9c88abf5-29e9-4ddd-f417-02fec1367000/listing",
        type: "nordic_clasic",
      },
      {
        id: "skating",
        name: "Langrenn Skating",
        description:
          "Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Bruk vår kjøpeguide om du trenger hjelp med å finne skiene som passer dine behov.",
        filter: {
          skiType: {
            in: ["NORDIC_SKATING"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/3c9b9c6a-1358-4701-5b7a-3499d7da1b00/listing",
        type: "nordic_skating",
      },
      {
        id: "alpine",
        name: "Alpin",
        description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Ta kontakt om du har spørsmål eller ønsker montering av bindinger.

[Kjøpeguide](https://sportienda.com/no/x)
          `,
        filter: {
          skiType: {
            in: ["ALPINE"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/3ef0a087-5e36-4091-92ff-12210e074500/listing",
        type: "alpine",
      },
      {
        id: "randonee",
        name: "Randonee",
        description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Ta kontakt om du har spørsmål eller ønsker montering av bindinger.

[Kjøpeguide](https://sportienda.com/no/x)
          `,
        filter: {
          skiType: {
            in: ["RANDONEE"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/d94fb3d8-b3ba-4018-2eaa-6f30a448a900/listing",
        type: "randonee",
      },
      {
        id: "accessories",
        name: "Tilbehør",
        filter: {
          skiType: {
            in: ["ACCESSORY"],
          },
        },
        hidden: true,
        type: "accessory",
      },
      // {
      //   id: "telemark",
      //   name: "Telemark",
      //   filter: {
      //     skiType: {
      //       in: ["TELEMARK"],
      //     },
      //   },
      //   img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/0436a328-3448-4b3a-88fd-0ac2b6e16000/listing",
      //   type: "TELEMARK",
      // },
      // {
      //   id: "touring",
      //   name: "Fjellski",
      //   filter: {
      //     skiType: {
      //       in: ["TOURING"],
      //     },
      //   },
      //   img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/bc3ca143-1c9f-42d3-d31f-88003a83b600/listing",
      //   type: "TOURING",
      // },
      {
        id: "snowboard",
        name: "Snowboard",
        filter: {
          skiType: {
            in: ["SNOWBOARD"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/fd5aa1b0-9a82-4111-9377-16cdcca52a00/listing",
        type: "snowboard",
      },
    ],
  },
];

const mainNavItems = [
  {
    id: "sell",
    name: "Selg din sykkel",
  },

  {
    id: "services",
    name: "Verksted og tjenester",
  },
  {
    id: "why",
    name: "Hvorfor Sportienda?",
  },
];

export function getNavigation(locale: string = "no"): Navigation {
  const categories = structure
    .filter((category) => category.showSubCategories)
    .map((category) => ({
      name: category.name,
      id: category.id,
      featured: category.subCategories
        ?.filter((subCategory) => !subCategory?.hidden)
        .map((subCategory) => ({
          id: subCategory.id,
          name: subCategory.name,
          href: `/${locale}/${category.id}/${subCategory.id}`,
          imageSrc: subCategory.img,
        })),
    }));
  const pages = structure
    .filter((category) => !category.showSubCategories)
    .map((category) => ({
      name: category.name,
      id: category.id,
      href: `/${locale}/${category.id}`,
      className: category.className || "",
    }));

  const mainNav = mainNavItems.map((item: any) => ({
    name: item.name,
    href: `/${locale}/${item.id}`,
  }));

  return { categories, pages, mainNav };
}

export function getCategory(id: string) {
  return structure.find((category) => category.id === id);
}

export function getSubCategory(categoryId: string, subCategoryId: string) {
  return getCategory(categoryId)?.subCategories?.find(
    (subCategory) => subCategory.id === subCategoryId
  );
}

function uuid4toYouTubeStyleId(uuid4: string) {
  const hex = uuid4.replace(/-/g, "");
  let num = BigInt("0x" + hex);
  const base64chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
  let youtubeStyleId = "";

  while (num > 0) {
    youtubeStyleId = base64chars[Number(num % BigInt(64))] + youtubeStyleId;
    num /= BigInt(64);
  }

  return youtubeStyleId;
}

export function youtubeStyleIdToUuid4(youtubeStyleId: string) {
  const base64chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
  let num = BigInt(0);

  for (let i = 0; i < youtubeStyleId.length; i++) {
    num = num * BigInt(64) + BigInt(base64chars.indexOf(youtubeStyleId[i]));
  }

  let hex = num.toString(16).padStart(32, "0");

  // Insert hyphens at the appropriate positions to format as a UUID4
  hex =
    hex.slice(0, 8) +
    "-" +
    hex.slice(8, 12) +
    "-" +
    hex.slice(12, 16) +
    "-" +
    hex.slice(16, 20) +
    "-" +
    hex.slice(20);

  return hex;
}

function nameToUrl(name: string) {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
}

type InventoryUrlObject = {
  id: string;
  object_type: string;
  name: string | null;
  inventory_bicycle?: { bicycle_type: any };
  inventory_ski?: { ski_type: any };
};

export const createInventoryUrl = (
  locale: string,
  object: InventoryUrlObject | undefined
) => {
  if (!object || !object.name) return "";
  //look up the category from type in structure and use id in url
  const id = object.id;

  const objectType = object.object_type;
  const subType =
    object?.inventory_bicycle?.bicycle_type || object?.inventory_ski?.ski_type;
  if (!objectType || !subType) return null;
  const category = structure.find((item) => item.type === objectType)?.id;
  const subCategory = structure
    .find((item) => item.type === objectType)
    ?.subCategories?.find((item) => item.type === subType)?.id;
  if (!category || !subCategory) return null;
  return `/${locale}/${category}/${subCategory}/${nameToUrl(
    object.name
  )}/${uuid4toYouTubeStyleId(id)}`;
};

export const services: Service[] = [
  // {
  //   id: "langrenn",
  //   name: "Oppmåling av langrennski",
  //   bookingId: "langrenn",
  // },
  // {
  //   id: "vinterlagring",
  //   name: "Vinterlagring av sykkel",
  //   bookingId: "vinterlagring",
  // },
  {
    id: "dekkskift",
    name: "Dekkskifte på sykkel",
    bookingId: "dekkskift",
  },
  {
    id: "sykkelservice",
    name: "Sykkelservice",
    bookingId: "sykkelservice",
  },
  {
    id: "elsykkelservice",
    name: "El-sykkelservice",
    bookingId: "sykkelservice-el-sykkel-fra-kr-699-copy",
  },
];

type trelloConfigType = {
  trelloBoardId: string;
  trelloPriceQueryListId: string;
  trelloOrderListId: string;
};

type trelloConfigLookupType = {
  [key: string]: trelloConfigType;
};

export const node_env = process.env.NODE_ENV;

const trelloConfigLookup: trelloConfigLookupType = {
  development: {
    trelloBoardId: "2bUK139U",
    trelloPriceQueryListId: "656a23815f307c61629ff6af",
    trelloOrderListId: "656a23815f307c61629ff6b0",
  },
  production: {
    trelloBoardId: "NxvD64ZU",
    trelloPriceQueryListId: "63b2ab420d656600ee5a94b4",
    trelloOrderListId: "63b2ab33782abd0059b3331a",
  },
};

export const trelloConfig = node_env ? trelloConfigLookup[node_env] : undefined;
