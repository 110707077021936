import { Badge } from "../atom/badge";
import { Button } from "../atom/button";
import { Divider } from "../atom/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../atom/dropdown";
import { Heading } from "../atom/heading";
import { Input, InputGroup } from "../atom/input";
import { Link } from "../atom/link";
import { Select } from "../atom/select";
import {
  usePimInventoriesQuery,
  type Inventory,
  InventoryStatus,
  InventoryType,
} from "@app/graphql/pim";
import PartnerInvnetoryListHeader from "../molecules/PartnerInventoryListHeader";
import {
  DescriptionListInline,
  DescriptionTermInline,
  DescriptionDetailsInline,
} from "../atom/description-list";
import { usePaginationTableState } from "../../hooks/usePaginationTable";
import { inventoryStatusColors } from "../../utils/colors";
import { createImageUrl, formatCurrency } from "../../utils/dom";
import FilterSingle, { FilterEnum } from "../molecules/FilterSingle";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/16/solid";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Pagination from "../molecules/Pagination";
import DuplicateInventory from "../molecules/DuplicateInventory";
function InventoryProperties({
  term,
  details,
}: {
  term?: string;
  details: string | null | undefined;
}) {
  if (!details) {
    return null;
  }
  return (
    <>
      {term && <DescriptionTermInline>{term}:</DescriptionTermInline>}
      <DescriptionDetailsInline>{details}</DescriptionDetailsInline>
    </>
  );
}
export function InventoryListingItem({
  inventory,
  index,
  asHeader,
}: {
  index: number;
  inventory: Inventory;
  asHeader?: boolean;
}) {
  const imageid = inventory?.inventoryImages?.nodes?.[0]?.id;
  const imageUrl = imageid
    ? createImageUrl(imageid)
    : "https://sportienda.com/bike.svg";
  const RootElement = asHeader ? "div" : "li";
  return (
    <RootElement>
      {!asHeader && <Divider soft={index > 0} />}
      <div className="flex items-center justify-between">
        <div className="flex gap-6 py-6">
          <div className="w-32 shrink-0">
            <Link href={inventory.id} aria-hidden="true">
              <img
                className="aspect-[3/2]  dark:bg-gray-100 rounded-lg shadow"
                src={imageUrl}
                alt=""
              />
            </Link>
          </div>
          <div className="space-y-1.5">
            <div className="text-base/6 font-semibold dark:text-gray-50">
              <Link href={inventory.id}>{inventory.name}</Link>
            </div>
            <div className="text-xs/6 text-zinc-500">
              <DescriptionListInline>
                <InventoryProperties details={inventory.type} />
                <InventoryProperties details={inventory.objectType} />

                {inventory.inventoryBicycleById && (
                  <>
                    <InventoryProperties
                      details={inventory.inventoryBicycleById.bicycleType}
                    />
                    <InventoryProperties
                      details={
                        inventory.inventoryBicycleById.electric
                          ? "EL"
                          : undefined
                      }
                    />
                    <InventoryProperties
                      term="Størrelse"
                      details={inventory.inventoryBicycleById.bicycleSize}
                    />
                  </>
                )}
              </DescriptionListInline>
            </div>
            <div className="text-base/6 text-zinc-600">
              {inventory.price ? formatCurrency(inventory.price) : ""}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Badge
            className="max-sm:hidden"
            color={inventoryStatusColors[inventory?.status || "DRAFT"]}
          >
            {inventory.status}
          </Badge>
          {!asHeader && (
            <Dropdown>
              <DropdownButton plain aria-label="More options">
                <EllipsisVerticalIcon />
              </DropdownButton>
              <DropdownMenu anchor="bottom end">
                <DropdownItem href={inventory.id}>Edit</DropdownItem>
                <DuplicateInventory inventoryId={inventory.id} />
                {/* <DropdownItem>Preview</DropdownItem>
                <DropdownItem>Delete</DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
    </RootElement>
  );
}

function PartnerInventoryListing({ orgId }: { orgId: string }) {
  const [searchParams] = useSearchParams();
  const STATUS_GROUPS = {
    default: ["DRAFT"],
    active: ["FOR_SALE", "FOR_SALE_HIDDEN", "AUCTION", "AUCTION_DONE"],
    sold: ["SOLD"],
    archived: ["RETRACTED"],
  };

  const page = searchParams.get("p") || "default";

  //@ts-ignore
  const statusFilter = STATUS_GROUPS[page] || STATUS_GROUPS.default;

  const { filter, offset, orderBy } = usePaginationTableState({
    filters: {
      soldByOrgId: { equalTo: orgId },
      name: (v: string) => ({ likeInsensitive: `%${v}%` }),
      status: { in: statusFilter },
    },
  });
  console.log("ORG", orgId, filter);
  let { data, loading } = usePimInventoriesQuery({
    variables: {
      filter,
      pagesize: 20,
      offset,
      orderBy: orderBy as any,
    },
  });

  const inventories = data?.inventories?.nodes?.map(
    (inventory) => inventory as Inventory
  );
  const orderBys = [
    { id: "NAME_DESC", name: "Sort by name" },
    { id: "CREATED_DESC", name: "Sort by date" },
    { id: "ON_SALE_FROM_DESC", name: "Sort by status" },
  ];

  return (
    <>
      <PartnerInvnetoryListHeader />

      <ul className="mt-10">
        {inventories?.map((inventory, index) => (
          <InventoryListingItem
            inventory={inventory}
            index={index}
            key={inventory.id}
          />
        ))}
      </ul>
      <Pagination
        totalCount={data?.inventories?.totalCount || 0}
        pageSize={20}
      />
      {loading && <div>Loading&hellip;</div>}
    </>
  );
}

export default function PartnerInventoryPage() {
  const { user } = useAuth0();
  console.log("USER", user);
  const orgId = user?.["org_id"] as string;
  if (!orgId) {
    return null;
  }
  return <PartnerInventoryListing orgId={orgId} />;
}
